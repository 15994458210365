<template>
  <div>
    afterSales 售后
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  created() {

  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
</style>